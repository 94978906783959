import "./index.css";
import { Button } from "@mui/material";

type Props = {
  toggleAddingNote: () => void;
};

function TopNavigationBar(props: Props) {
  return (
    <div className="navbar">
      <Button variant="contained" onClick={props.toggleAddingNote}>
        Add Note
      </Button>
    </div>
  );
}

export default TopNavigationBar;
