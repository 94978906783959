import React from "react";
import "./App.css";
import TopNavigationBar from "./components/TopNavigationBar";
import { AddNote } from "./components/Note";
import NotesList from "./components/NotesList";

function App() {
  const [isAddingNote, setIsAddingNote] = React.useState(false);

  const toggleAddingNote = () => {
    setIsAddingNote(!isAddingNote);
  };
  return (
    <div style={{ position: "relative" }}>
      <TopNavigationBar toggleAddingNote={toggleAddingNote} />
      <NotesList isAddingNote={isAddingNote} />
      <AddNote isOpen={isAddingNote} onClose={() => setIsAddingNote(false)} />
    </div>
  );
}

export default App;
