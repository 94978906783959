import "./Display.css";
import Button from "@mui/material/Button";

type Props = {
  note: Note;
  onDelete: (id: string) => void;
};
function DisplayNote(props: Props) {
  return (
    <div className="note-display">
      <div
        style={{ textAlign: "center" }}
        dangerouslySetInnerHTML={{ __html: props.note.text || "" }}
      />

      <Button
        variant="contained"
        size="small"
        onClick={() => props.onDelete(props.note._id || "")}
      >
        Delete
      </Button>
    </div>
  );
}

export default DisplayNote;
