import "./Add.css";
// import Button from "../Button";
import { useState } from "react";
import { saveNote } from "../../actions/note";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

function AddNote(props: Props) {
  const [note, setNote] = useState("");
  const handleSaveNote = async () => {
    await saveNote({ text: note });
    props.onClose();
  };

  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <DialogTitle>Add new note</DialogTitle>
      <DialogContent>
        <ReactQuill theme="snow" value={note} onChange={setNote} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSaveNote}>
          Save
        </Button>
        <Button variant="outlined" onClick={props.onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddNote;
