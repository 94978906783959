import { useEffect, useState } from "react";
import { getNotes } from "../../actions/note";
import DisplayNote from "../Note/Display";
import "./index.css";
import { deleteNote } from "../../actions/note";

type Props = {
  isAddingNote: boolean;
};

function NotesList(props: Props) {
  const [notes, setNotes] = useState<Note[]>([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  useEffect(() => {
    async function listNotes() {
      const _notes = await getNotes();
      setNotes(_notes);
    }
    if (!props.isAddingNote) {
      listNotes();
    }
  }, [props.isAddingNote, shouldRefresh]);

  const handleDeleteNote = async (id: string) => {
    await deleteNote(id);
    setShouldRefresh(!shouldRefresh);
  };
  return (
    <div className="notes-list-container">
      {notes.map((note) => {
        return (
          <DisplayNote note={note} onDelete={handleDeleteNote} key={note._id} />
        );
      })}
    </div>
  );
}

export default NotesList;
