import axios from "axios";

export async function saveNote(note: Note) {
  await axios.post("/note", {
    note,
  });
}

export async function getNotes() {
  const notes = await axios.get<any, { data: Note[] }>("/notes");
  return notes.data;
}

export async function deleteNote(id: string) {
  await axios.delete("/note", { data: { id } });
}
